import React, { ReactNode } from 'react';
import { MainLayout } from '../layout/mainLayout';
import { H1, H5 } from '../primitives/appTitle';
import classnames from 'classnames';

const DefaultMessage = 'Page not found';

export const PageNotFoundHeader = (
  { message }: { message?: ReactNode } = { message: DefaultMessage },
) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '70vh',
    }}
  >
    <H1>
      <div className="page404">404</div>
    </H1>
    <div>
      <H5>{message}</H5>
    </div>
  </div>
);

const PageNotFoundInfos = ({ infos }: { infos: ReactNode }) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <H5>{infos}</H5>
  </div>
);

export type PageNotFoundProps = {
  message?: ReactNode;
  infos?: ReactNode;
};
const DefaultPageNotFoundProps: PageNotFoundProps = {
  message: DefaultMessage,
  infos: undefined,
};
const PageNotFound = ({
  message,
  infos,
}: PageNotFoundProps = DefaultPageNotFoundProps) => (
  <MainLayout
    className={classnames('page-form  light')}
    showContact={false}
    pageName="notFound"
  >
    <PageNotFoundHeader message={message} />
    {infos && <PageNotFoundInfos infos={infos} />}
  </MainLayout>
);

export default PageNotFound;
